<template>
  <van-row class="buytrain">
    <van-image class="pic_margin" width="100vw" src="/hotel-banner.png" />
    <van-row class="pic-next-cont itembk">
      <van-row style="padding: 10px">
        <!-- 目的城市cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="space-between" align="bottom" style="height: 20px;">
              <van-col>
                <van-row type="flex" justify="center">
                  <span style="font-size: 10px;color: #adadad">目的地</span>
                </van-row>
              </van-col>
              <van-col>
                <van-row type="flex" justify="center">
                  <div style="font-size: 10px;color: #adadad"></div>
                </van-row>
              </van-col>
            </van-row>
            <van-row type="flex" justify="space-between" align="center" style="height: 40px">
              <van-col span="24">
                <van-row type="flex" justify="start" @click="chooseCity()">
                  <h4 class="animate__animated animate__delay-08" ref="left">{{hotelOrderInfo.city||'请选择'}}</h4>
                </van-row>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        <!-- 目的城市cell -->
        <!-- 出发时间选择cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="space-between" align="center" @click="show_calendar = true">
              <van-col>
                <span style="font-size: 10px;color: #adadad;display:block">入住日期</span>
                <span class="h4">
                  {{hotelOrderInfo.checkInDate|comverTime('MM月DD日')}}
                  <small>{{hotelOrderInfo.checkInDate| comverTime('ddd')}}</small>
                </span>
              </van-col>
              <van-col>
                <p class="hidep">.</p>
                <p class="toptitle">-{{hotelOrderInfo|diffdate()}}天-</p>
              </van-col>
              <van-col>
                <span style="font-size: 10px;color: #adadad;display:block">离店日期</span>
                <span class="h4">
                  {{hotelOrderInfo.departDate|comverTime('MM月DD日')}}
                  <small>{{hotelOrderInfo.departDate| comverTime('ddd')}}</small>
                </span>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        <!-- 出发时间选择cell -->
        <!-- 关键词cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="start" align="bottom" style="height: 20px;">
              <van-col>
                <van-row type="flex" justify="center">
                  <span style="font-size: 10px;color: #adadad">我的附近</span>
                </van-row>
              </van-col>
            </van-row>
            <van-row type="flex" justify="start" align="center" style="height: 40px">
              <van-col>
                <van-row type="flex" justify="start" align="bottom">
                  <van-col>
                    <van-field style="padding-left:0;font-size:1.4rem" v-model="hotelOrderInfo.keyword" placeholder="搜索酒店名/地名/关键词" />
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        <!-- 关键词cell -->
        <!-- 搜索按钮cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="center" align="center" style="height: 60px">
              <van-button style="border-radius: 8px;font-size: 16px" block @click="searchTrain" color="linear-gradient(to right, #4bb0ff, #2c50d4)">搜 索</van-button>
            </van-row>
          </template>
        </van-cell>
        <!-- 搜索按钮cell -->
      </van-row>
    </van-row>

    <!-- <a href="https://uri.amap.com/marker?position=117.216961,39.179376&name=所在的位置名称">高德地图</a> -->
    <!-- <a href="http://api.map.baidu.com/marker?location=纬度,经度&title=所在位置名称&content=所在位置的简介（可选）&output=html">百度地图</a> -->
    <!--日期选择-->
    <van-calendar v-model="show_calendar" color="#198BDA" confirm-text="确定" type="range" :show-confirm="false" :default-date="[new Date(hotelOrderInfo.checkInDate),new Date(hotelOrderInfo.departDate)]" @confirm="calendarConfirm" />

    <!-- 遮罩层 -->
    <LoadingCircle :show="loading" />
  </van-row>
</template>

<script>
import { mapGetters } from "vuex";
import logic from "@/services/commonLogic";
import LoadingCircle from "@/components/LoadingCircle";
import { DiffDate } from "@/services/date.js";
export default {
  name: "BuyTrain",
  computed: {
    ...mapGetters(["travelInfo", "hotelOrderInfo"])
  },
  components: { LoadingCircle },
  created() {
    window.setTitle("选择入住城市");
    // 如果缓存中没有数据，从差旅项目信息中同步数据。VUEX
    if (!this.hotelOrderInfo) this.$store.dispatch("hotel/initHotelOrder");
  },
  data() {
    return {
      loading: false,
      show_calendar: false // 展示日历
    };
  },
  methods: {
    // 选择城市 转到选择城市页面 参数是vuex的actions名称
    chooseCity(vuxFunction = "hotel/setHotelOrderFrom") {
      this.loading = true;
      this.$store.dispatch("hotel/setHotelOrder", this.hotelOrderInfo);
      setTimeout(() => {
        this.$router.push({
          path: "/selectCity",
          query: logic.queryPathEncrypt({ function: vuxFunction })
        });
      }, 100);
    },
    // 日历 选择日期
    calendarConfirm(date) {
      this.$store.dispatch("hotel/setHotelOrder", {
        checkInDate: date[0],
        departDate: date[1]
      });
      this.show_calendar = false;
    },
    // 搜索按钮
    searchTrain() {
      this.$router.push("/searchHotel");
    }
  },
  filters: {
    // 间隔几日
    diffdate: function (info) {
      return DiffDate(info.checkInDate, info.departDate);
    }
  }
};
</script>

<style lang="less" src="@/styles/buyCommon.less" scoped></style>
<style scoped>
</style>